
import {
  IonPage,
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLoading,
  IonFooter
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BaseCard from '@/components/Card/BaseCard.vue';
import userFull from '../../resources/icons/user-square.svg';
import bigPicture from '../../resources/icons/bigPicture.svg';
import { LoginService, TokenService } from '@/services';
import { ValidationError, LoginUserProp, Auth0Token } from '@/types';
import { isDischargedCheck } from '@/helpers/discharge.helper';
import axios from 'axios';
import { useStore } from 'vuex';
import { FETCH_CURRENT_USER } from '@/store/actions.type';
import { useToast } from '@/composables/useToast';

export default defineComponent({
  name: 'LoginPage',
  components: {
    BaseCard,
    IonPage,
    IonContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonInput,
    IonItem,
    IonLoading,
    IonFooter
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loginService = new LoginService();
    const tokenService = new TokenService();
    const toast = useToast();

    // Loading State
    const isLoadingRef = ref<boolean>(false);
    const loadingMessageRef = ref<string>('');
    const setLoading = (state: boolean, message = 'Loading') => {
      loadingMessageRef.value = message;
      isLoadingRef.value = state;
    };

    // Login Form
    const user = ref<LoginUserProp>({
      email: '',
      otp: ''
    });
    const errors = ref<ValidationError>({
      errors: [],
      message: ''
    });
    const resetErrors = () => (errors.value = { errors: [], message: '' });
    const resetPageState = () => {
      user.value = { email: '', otp: '' };
    };

    onMounted(() => {
      detectMagicLink();
    });

    const detectMagicLink = async () => {
      if (route.query.email && route.query.code) {
        user.value = {
          email: route.query.email as string,
          otp: route.query.code as string
        };
        await login();
      }
    };

    const sendMagicLink = async () => {
      resetErrors();
      toast.close();
      setLoading(true, 'Sending email');

      try {
        await loginService.validateEmail({
          email: user.value.email
        });
        await router.push({
          name: 'check-your-email',
          params: {
            email: user.value.email
          }
        });
      } catch (e) {
        if (!axios.isAxiosError(e)) {
          await toast.open({
            message: 'An unknown error occurred.',
            color: 'warning',
            duration: 3000
          });
          console.error(e);
          return;
        }

        if (e.response?.status === 422) {
          errors.value = e.response.data;
          return;
        }

        if (e.response?.status === 403) {
          await toast.open({
            message: 'Please check email address and try again',
            color: 'warning',
            duration: 3000
          });
          return;
        }

        if (e.response?.status === 429) {
          await toast.open({
            message: e.response.data.message,
            color: 'warning',
            duration: 3000
          });
          return;
        }

        if (e.response?.data.message) {
          await toast.open({
            message: e.response?.data?.message,
            color: 'warning',
            duration: 3000
          });
          return;
        }

        await toast.open({
          message: 'Unable to connect. Please try again.',
          color: 'warning',
          duration: 3000
        });
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    const errorList = computed(() => errors.value.errors);

    const login = async () => {
      resetErrors();
      setLoading(true, 'Validating');

      try {
        const response: Auth0Token = await tokenService.fetchToken(user.value);
        tokenService.setAuthenticatedUser(response);
        await store.dispatch(FETCH_CURRENT_USER);
        const isDischarged: boolean = await isDischargedCheck();
        await router.push(isDischarged ? '/discharged' : '/tabs');
        resetPageState();
      } catch (e) {
        if (!axios.isAxiosError(e)) {
          await toast.open({
            message: 'Unable to connect. Please try again.',
            color: 'warning',
            duration: 3000
          });
          console.error(e);
        }
        if (axios.isAxiosError(e) && e.response?.status === 422) {
          errors.value = e.response.data;
          return;
        }
        if (axios.isAxiosError(e) && e.response?.status === 403) {
          await router.push({
            name: 'invalid-link',
            params: {
              email: user.value.email
            }
          });
          return;
        }
        if (axios.isAxiosError(e) && e.response?.data.message) {
          await toast.open({
            message: e.response?.data?.message,
            color: 'warning',
            duration: 3000
          });
          return;
        }
      } finally {
        setLoading(false);
      }
    };

    return {
      user,
      userFull,
      bigPicture,
      errors: errorList,
      resetErrors,
      sendMagicLink,
      login,
      isLoadingRef,
      loadingMessageRef,
      setLoading,
      detectMagicLink
    };
  }
});
